$(function () {
  if ( _.get($.siteConfiguration,"show_registration_explanation" ) ){
    renderMarkdown($('#registration-explanation'), $.polyglot.t('registration_explanation', $.interpolations));
  }

  if ( _.get($.siteConfiguration,"registration_model_field.display_help" ) ){
    renderMarkdown($('#model-help'), $.polyglot.t('model_help_text', $.interpolations));
  }

  if ( _.get($.siteConfiguration,"show_registration_disclaimer" ) ){
    renderMarkdown($('#registration-disclaimer'), $.polyglot.t('registration_disclaimer', $.interpolations));
  }
});

$(document).ready(function() {

  if ( _.get($.siteConfiguration, ["enable_esp", "enable_care_page"] ) ){
    renderMarkdown($('#care-page-headers'), $.polyglot.t('care_page_headers', $.interpolations));
    renderMarkdown($('.care-explanation'), $.polyglot.t('care_explanation', $.interpolations));
    renderMarkdown($('#care-benefits-explanation'), $.polyglot.t('care_benefits_explanation', $.interpolations));
    renderMarkdown($('#care-disclaimer'), $.polyglot.t('care_disclaimer', $.interpolations));
    renderMarkdown($('#care-terms'), $.polyglot.t('care_terms', $.interpolations));
    renderMarkdown($('#care-faq'), $.polyglot.t('care_faq', $.interpolations));
    renderMarkdown($('#care-faq-condensed'), $.polyglot.t('care_faq_condensed', $.interpolations));
  }
  if ( _.get($.siteConfiguration, "enable_esp_sales" ) ){
    renderMarkdown($('#protect-page-product-image-text'), $.polyglot.t('protect_page_product_image_text', $.interpolations));
    renderMarkdown($('#protect-page-product-image-text-2'), $.polyglot.t('protect_page_product_image_text_2', $.interpolations));
    renderMarkdown($('#protect-page-product-image-text-3'), $.polyglot.t('protect_page_product_image_text_3', $.interpolations));
    renderMarkdown($('#protect-page-product-intro'), $.polyglot.t('protect_page_product_intro', $.interpolations));
    renderMarkdown($('#protect-page-benefit-heading-text'), $.polyglot.t('protect_page_benefit_heading_text', $.interpolations));
    renderMarkdown($('#protect-page-activate-text'), $.polyglot.t('protect_page_activate_text', $.interpolations));
    renderMarkdown($('#protect-page-banner-text'), $.polyglot.t('protect_page_banner_text', $.interpolations));
  }
  if ( _.get($.siteConfiguration, "enable_esp_sales" ) ){
    renderMarkdown($('#care_plan_1_message'), $.polyglot.t('care_plan_1_message', $.interpolations));
    renderMarkdown($('#care_plan_2_message'), $.polyglot.t('care_plan_2_message', $.interpolations));
    renderMarkdown($('#renewal-decline-text'), $.polyglot.t('care_decline_message', $.interpolations));
    renderMarkdown($('#care-benefits-heading-text'), $.polyglot.t('esp_benefits_title_heading', $.interpolations));
  }
});

$(document).ready(function() {
  renderMarkdown($('#footer-text'), $.polyglot.t('footer_text', $.interpolations));
  if ( _.get($.siteConfiguration,"enable_footer_links" ) ){
    renderMarkdown($('#footer_links'), $.polyglot.t('footer_links', $.interpolations));
  }
});

$(function() {
  

  if ($(":input[name*='user[email]']").length) {
    $(":input[name*='user[email]']").rules("add", {
      regex: "^[A-Za-z0-9!%&'*+/=?^_`{|}~-]+(?:.[A-Za-z0-9!%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$".replace(new RegExp("\\.", "g"), "\\\."),
      required: $(":input[name*='user[email]']")[0].required,
      messages: {
        regex: $.polyglot.t('validations.email')
      }
    });
  }

  if ($(":input[name*='user[phone]']").length) {
    var phone_field = $(":input[name*='user[phone]']");
    var isRequired = phone_field[0].required

    if ( _.get($.siteConfiguration,"user_phone_field.display" )){
      phone_field.data("required_phone", isRequired);
      phone_field.rules('add', {
        strictPhone: isRequired
      });

      if (phone_field.val().indexOf("_") < 0) phone_field.data("valid_phone", true);

      $("#registria_form").on('blur change keyup', phone_field, function() {
        if(phone_field.data("mask")){
          var optional = phone_field.data("mask").indexOf("?");
          if (optional != -1 && phone_field.val().indexOf("_") >= optional) {
            phone_field.data("valid_phone", true);
          } else if (phone_field.val().indexOf("_") >= 0) {
            phone_field.data("valid_phone", false);
          } else {
            phone_field.data("valid_phone", true);
          }
        }
      });
    }
  }
  if ($(".mask-money").length) {
    $(".mask-money").on('input', function() {
      if(this.value.length > 0) {
        if (this.value.length === 1) { this.value = '0'+this.value; }
        this.value = parseFloat(this.value.replace(/[^\d]/g,'').replace(/(\d\d?)$/,'.$1')).toFixed(2);
      }
    });
    $(".mask-money").on('blur', function() {
      if($(".mask-money").val() <=0 ){ $(".mask-money").val('');}
    });
  }
  // Price paid validation
  $.validator.addMethod('strictPrice', function(value, element) {
    return $(element).data("valid_price");
  }, $.polyglot.t('validations.required'));

  if ($(":input[name*='registration[price_paid]']").length) {
    var price_field = $(":input[name*='registration[price_paid]']");
    var isRequired = price_field[0].required
    if ( _.get($.siteConfiguration,"registration_price_paid_field.display" )){
      price_field.data("required_price", isRequired);
      price_field.rules('add', {
        strictPrice: isRequired
      });
      $("#registria_form").on('blur change keyup', price_field, function() {
        if (price_field.val()) {
          price_field.data("valid_price", true);
        }
        else {
          price_field.data("valid_price", false);
        }
      });
    }
  }
});
$(function() {
  // Weber serial validation
  if($("#serial").length && $.siteConfiguration.site_name === "Weber" ){
    var bypass = $.siteConfiguration.registration_serial_number_field.bypass;
    var requiredAttempts = $.siteConfiguration.registration_serial_number_field.required_attempts;
    var attempts = 0;
    var subdomain = window.location.hostname.split(".")[0];
    var translate = function translate(text) {
      switch (text) {
        case 'invalid/not found serial number':
          return $.polyglot.t('validations.serial_number_invalid');
        case 'serial number already used':
          return $.polyglot.t('validations.serial_number_used');
        case 'serial number not valid for entered model':
          return $.polyglot.t('validations.serial_number_mismatch');
        case 'invalid purchase date specified':
          return $.polyglot.t('validations.purchase_date_invalid');
      }
    }

    $("#serial").rules("add",{
      "remote" :{
        param: {
          url: '/' + subdomain + '/validate_serial?',
          type: "POST",
          async: true,
          data:
          {
            serial_number: function(){
              return $("#serial").val();
            },
            product_id: function(){
              return $("#product").val();
            },
            purchase_date: function(){
              return $("#purchase_date").val();
            }
          },
          dataFilter: function(data) {
            data=JSON.parse(data);
            attempts++;
            if(data.success) {
              $("#model").val(data.product_name+' ('+data.model+')');
              $('#product').val(data.product_id);
              document.getElementById("model").style.textTransform = "capitalize";
              $("label[for='model'] .frauto-list > li.selected").removeClass('selected');
              $('label[for="serial"]').css("margin-bottom","0px");
              $('#registration_status_short_code').val("");
              return true;
            } else if (bypass && attempts > requiredAttempts) {
              $('label[for="serial"]').css("margin-bottom","0px");
              $('#registration_status_short_code').val("invalid_serial");
              return true;
            } else {
              if(data.message.indexOf("invalid purchase date specified") < 0 ) {
                $("#model").val('');
                $('#product').val('');
              } else {
                $("#serial").trigger('focus');
              }
              if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                $('label[for="serial"]').css("margin-bottom","70px");
              } else {
                $('label[for="serial"]').css("margin-bottom","40px");
              }
              var msg = translate(data.message);
              return "\"" + msg + "\"";
            }
          }
        }
      }
    });
    $('#serial').on('keyup',function(e){
      e.stopPropagation();
      $("#serial").removeClass("invalid");
      $('label[for="serial"].invalid').html("");
      $('label[for="serial"]').css("margin-bottom","0px");
    });
    $('#model').on('change blur', function () {
      $('#serial').removeData("previousValue");
    });
    $('#purchase_date').on('change', function () {
      $('#serial').removeData("previousValue");
    });
  }
});


$(document).ready(function() {
  if (!localStorage.bannerClosed) {
    $('.cookieinfo-banner').css('display', 'inherit');
  } else {
    $('.cookieinfo-banner').css('display', 'none');
  }
  $('.cookieinfo-accept').on('click', function() {
    $('.cookieinfo-banner').css('display', 'none');
    $('#cookie_policy_accepted').val(1);
    localStorage.bannerClosed = 'true'
  });
});